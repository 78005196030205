.header {
    width: 100%;
    z-index: 11;
    transition: all 0.6s;
    position: absolute;
    top: 0;
    left: 0;

    .button {
        background: #E1EAF8;
        color: $accent-1;
        font-size: 16px;
        height: 44px;
        max-width: 150px;

        @media (max-width: $lg) {
            margin-left: auto; } }

    &__dop {
        background: $accent-1;

        ul {
            display: flex;
            align-items: center;
            height: 50px;
            justify-content: space-between;

            @media (max-width: $lg) {
                overflow-x: scroll;
                gap: 20px; }

            a {
                color: #FFFFFF80;
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap; } } }

    &__main {
        background: white;

        .container-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 108px;

            @media (max-width: $lg) {
                height: 80px;
                justify-content: initial; } } }

    &__nav {
        &.is-active {
            left: 0;
            transition: transform .3s ease; }

        @media (max-width: $lg) {
            margin-left: 0;
            min-width: 100vw;
            width: 100%;
            left: calc( -100vw - 10px );
            height: calc( 100vh - 104px );
            position: absolute;
            background: #00000052;
            backdrop-filter: blur(10px);
            top: 123px;
            padding-top: 48px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start; }

        ul {
            display: flex;
            align-items: center;
            gap: 40px;

            @media (max-width: 1380px) {
                gap: 30px; }

            @media (max-width: $lg) {
                flex-direction: column;
                gap: 30px; }

            a {
                color: #71768B;
                font-size: 16px;
                font-weight: 500;
                line-height: 180%;

                @media (max-width: $lg) {
                    color: white; } } } }

    &__hamburger {
        display: none;

        @media (max-width: $lg) {
            display: block; } } }
