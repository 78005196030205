.footer {
    display: flex;
    align-items: center;
    margin-top: 24px;
    flex-direction: column;
    background: white;
    position: relative;
    z-index: 2;

    > .container-main {
        height: 100px;

        @media (max-width: $lg) {
            height: auto; } }

    .container-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: $lg) {
            padding-top: 20px;
            padding-bottom: 20px;
            align-items: baseline;
            flex-direction: column-reverse;
            gap: 20px;
            max-width: fit-content; }

        .nav {
            ul {
                display: flex;
                gap: 40px;
                row-gap: 16px;
                justify-content: flex-end;
                flex-wrap: wrap;

                @media (max-width: $lg) {
                    max-width: 100%;
                    justify-content: flex-start; }

                li {
                    @media (max-width: $lg) {
                        width: calc( (100% / 2) - 32px ); } }

                a {
                    font-size: 16px;
                    font-weight: 500;
                    color: $accent-2; } } } }

    &__bottom {
        background: $accent-1;
        width: 100%;

        .container-main {
            @media (max-width: $lg) {
                padding: 0;
                padding-right: 20px;
                max-width: calc( 100% - 20px ); } }

        ul {
            display: flex;
            align-items: center;
            height: 50px;
            justify-content: flex-end;
            gap: 24px;
            width: 100%;

            a {
                color: #FFFFFF80;
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;

                @media (max-width: $lg) {
                    font-size: 14px; } } }

        &.dop {
            ul {
                justify-content: space-between;

                @media (max-width: $lg) {
                    overflow-x: scroll;
                    gap: 20px;
                    padding: 0 10px; } } } } }
