.single-promo {
    background: $accent;
    padding: 32px;
    border-radius: 24px;

    h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 120%;
        font-family: 'Outfit', sans-serif;
        color: #EEF0FF;

        @media (max-width: $lg) {
            font-size: 40px;
            margin-bottom: 6px; } }

    > p {
        font-size: 20px;
        font-weight: 500;
        line-height: 160%;
        margin-bottom: 38px;

        @media (max-width: $lg) {
            font-size: 16px;
            margin-bottom: 20px; } }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        font-family: 'Outfit', sans-serif;
        color: $accent-4;
        margin-bottom: 32px;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            margin-bottom: 20px; }

        &-title {
            display: flex;
            align-items: center; }

        &:last-child {
            margin-bottom: 0; }

        picture {
            height: 24px; }

        img {
            margin-right: 12px; }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 160%;
            font-family: 'Inter';
            color: $accent-2; } }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px; }

        .single-promo__item {
            margin-bottom: 0;

            @media (max-width: $lg) {
                flex-direction: row; }

            picture {
                height: 64px; }

            &::after {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #718BE3;
                margin-left: 26px;

                @media (max-width: $lg) {
                    display: none; } }

            &:last-child {
                &::after {
                    content: none; } }

            p {
                font-size: 16px;
                font-weight: 600;
                line-height: 120%;
                font-family: 'Outfit', sans-serif;
                margin-bottom: 2px;
                color: $accent-2;

                &:last-child {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 120%;
                    margin-bottom: 0; } }

            h4 {
                font-size: 20px;
                font-weight: 600;
                line-height: 120%;
                font-family: 'Outfit', sans-serif;
                color: $accent-4; } } } }
