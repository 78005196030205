$accent: #141928;
$accent-1: #0b5dd7;
$accent-2: #71768b;
$accent-3: #2b3042;
$accent-4: #eef0ff;

$font-family-base: "Inter";

$lg: "1279px";
$sm: "1023px";
$md: "767px";
