.block-all {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px; }

    &__block {
        >p {
            color: $accent-2;
            font-size: 18px;
            font-weight: 500;
            line-height: 180%;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0; } }

        >h3 {
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
            margin-bottom: 16px; }

        img {
            margin-bottom: 32px;
            border-radius: 16px;
            width: 100%;
            object-fit: cover;

            @media (max-width: $lg) {
                width: 100%;
                height: auto; } } } }
