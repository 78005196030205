.text-block {
    display: flex;
    flex-direction: column;
    gap: 30px;

    h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 120%;
        font-family: 'Outfit', sans-serif;
        color: $accent-4;

        @media (max-width: $lg) {
            font-size: 40px; } }

    .wp-block-group__inner-container {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (max-width: $lg) {
            gap: 16px; } }

    > .wp-block-group {
        padding: 32px;
        border-radius: 24px; }

    .wp-block-group {
        background: $accent;

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 120%;
            font-family: 'Outfit', sans-serif;
            color: $accent-4;

            @media (max-width: $lg) {
                font-size: 30px; } }

        h3 {
            font-size: 30px;
            font-weight: 600;
            line-height: 120%;
            font-family: 'Outfit', sans-serif;
            color: $accent-4;

            @media (max-width: $lg) {
                font-size: 24px; } }

        h4 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            font-family: 'Outfit', sans-serif;
            color: $accent-4;
            margin-top: -8px;

            @media (max-width: $lg) {
                font-size: 20px; } }

        ul {
            margin-top: -16px;
            padding-left: 16px;
            list-style: disc;
            display: flex;
            flex-direction: column;
            gap: 16px;

            @media (max-width: $lg) {
                margin-top: 0; }

            li {
                font-size: 16px;
                font-weight: 500;
                line-height: 160%;
                color: $accent-2;

                &::marker {
                    color: #718BE3; } } }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 160%; }

        .wp-block-table {
            width: 100%;
            background: #0E111C;
            border-radius: 24px;
            margin: 0;

            @media (max-width: $lg) {
                overflow-x: scroll; }

            table {
                @media (max-width: $lg) {
                    min-width: 700px; } }

            tr {
                height: 62px;

                td {
                    color: $accent-3;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    border: 0;
                    border-bottom: 2px solid $accent;
                    text-align: center;
                    width: 10%;

                    &:first-child {
                        padding-left: 32px;
                        padding-right: 32px;
                        text-align: left;
                        white-space: nowrap; }

                    &:last-child {
                        padding-right: 32px;
                        text-align: right; }

                    img {
                        margin-right: 10px;
                        vertical-align: middle;

                        &:nth-child(2), &:nth-child(3) {
                            margin-top: 16px; } } }

                &:first-child {
                    height: 86px;

                    td {
                        color: #FFFFFF;
                        font-size: 18px;
                        line-height: 21px;
                        font-weight: 600;
                        font-family: 'Outfit', sans-serif;

                        &:first-child {
                            padding-left: 32px; }

                        &:last-child {
                            padding-right: 32px;
                            text-align: right; } } }

                &:last-child {
                    td {
                        border-bottom: 0; } } } }

        .table-two {
            tr {
                td {
                    &:first-child {
                        width: 1%; }
                    &:last-child {
                        text-align: left; } } } } } }
