.block-table {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: baseline;
            gap: 8px; } }

    &__block {
        >p {
            color: $accent-2;
            font-size: 18px;
            font-weight: 500;
            line-height: 180%;
            margin-bottom: 32px; }

        .bg-table {
            background: #FAFAFA;
            border-radius: 24px;

            @media (max-width: $lg) {
                padding: 0; } }

        .table-v1, .table-v2, .table-v3, .table-v4 {
            width: 100%;
            background: #FAFAFA;
            border-radius: 24px;

            @media (max-width: $lg) {
                overflow-x: scroll;
                background: #FAFAFA; }

            table {
                table-layout: fixed;
                width: 100%;

                @media (max-width: $lg) {
                    min-width: 700px; } }

            tr {
                height: 68px;

                td {
                    color: $accent-2;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 25px;
                    border-bottom: 2px solid #FAFAFA;
                    text-align: center;

                    &:first-child {
                        padding-left: 32px;
                        text-align: left; }

                    &:last-child {
                        padding-right: 32px;
                        text-align: right; }

                    a {
                        color: $accent-1; } }

                &:first-child {
                    height: 88px;

                    td {
                        color: $accent-3;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 120%;
                        font-family: 'Outfit', sans-serif;

                        &:nth-child(3), &:nth-child(4) {
                            text-align: center; }

                        &:first-child {
                            padding-left: 32px; }

                        &:last-child {
                            padding-right: 32px;
                            text-align: right; } } }

                &:last-child {
                    td {
                        border-bottom: 0; } } } } } }
