body {
    background: #F5F5F5;
    font-family: "Inter";
    margin: 0;
    font-weight: 500; }

.page {
    overflow-x: initial;
    @media (min-width: 1921px) {
        max-width: none !important; }

    &__content {
        position: relative;
        overflow: initial;
        padding-top: 184px;

        @media (max-width: $lg) {
            padding-top: 145px; }

        > .container-main {
            display: flex;
            flex-direction: row-reverse;
            gap: 20px;

            @media (max-width: $lg) {
                flex-direction: column; } } } }

.container-main {
    max-width: 1360px;
    margin: 0 auto;

    @media (max-width: 1380px) {
        padding: 0 20px; }

    @media (max-width: $lg) {
        padding: 0 10px; } }

.main {
    display: flex;
    flex-direction: column;
    gap: 20px;

    >section {
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;

        @media (max-width: $lg) {
            padding: 20px; } } }

.sidebar {
    min-width: 320px;
    max-width: 320px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 32px;
    align-self: flex-start;
    top: 0;
    position: sticky;
    background: #FFFFFF;
    border-radius: 16px;

    @media (max-width: $lg) {
        max-width: calc( 100% - 40px );
        position: relative;
        padding-bottom: 14px; }

    h2 {
        font-family: 'Outfit', sans-serif;
        font-size: 30px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 32px;
        color: $accent-3;

        @media (max-width: $lg) {
            margin-bottom: 12px; } }

    ul {
        display: flex;
        flex-direction: column;

        @media (max-width: $lg) {
            flex-direction: row;
            overflow-x: scroll; }

        li {
            padding: 12px;

            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 180%;
                color: #71768B;

                @media (max-width: $lg) {
                    white-space: nowrap; } } } } }

.section-title {
    font-size: 42px;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    font-family: 'Outfit', sans-serif;
    color: $accent-3;

    @media (max-width: $lg) {
        font-size: 30px;
        line-height: 120%; } }

.text {
    color: $accent-2;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500; }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    transition-property: all;
    transition-duration: .6s;
    width: 100%;
    height: 56px;
    background: $accent-1;
    font-family: 'Outfit', sans-serif;
    white-space: nowrap;
    max-width: 204px;

    &:hover {
        opacity: 0.8;
        color: #FFFFFF; } }

.text-page {
    padding-top: 24px;

    h1 {
        margin-bottom: 24px; }

    h2 {
        margin-bottom: 12px; }

    p {
        margin-bottom: 12px; }

    ul {
        list-style: disc;
        padding-left: 24px;
        margin-bottom: 12px;

        li {
            color: $accent-2;
            font-size: 20px;
            font-weight: 400;
            line-height: 180%;

            b {
                color: $accent-3; } } } }

.link {
    display: flex;
    align-items: center;
    color: $accent-1;
    gap: 8px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    font-weight: 600; }

table {
    border-collapse: collapse; }

h1,h2,h3,h4,h5,h6 {
    padding: 0;
    margin: 0;
    line-height: 150%;
    color: $accent-3; }

p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 156%;
    color: #71768B; }

a {
    text-decoration: none; }

button {
    border: none;
    transition-property: all;
    transition-duration: .6s;
    padding: 0;
    background: none;

    &:hover {
        opacity: 0.8; } }

@keyframes opacity {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.bonus {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__block {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 10px; } }

    &__item {
        width: 100%;
        border-radius: 16px;
        background: rgb(250, 250, 250);
        padding: 52px 32px 52px 32px;

        @media (max-width: $lg) {
            padding: 16px;
            width: 90%; }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 16px;
            font-family: 'Outfit', sans-serif; }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 180%;
            margin-bottom: 12px; }

        a {
            display: flex;
            align-items: center;
            gap: 8px;
            height: 52px;
            color: $accent-1;
            font-family: 'Outfit', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 120%; } } }

.reviews {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $lg) {
        flex-direction: column; }

    &__item {
        background: $accent;
        border-radius: 24px;
        width: 100%;
        padding: 32px;

        @media (max-width: $lg) {
            max-width: fit-content; }

        &-header {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 24px;

            img {
                border-radius: 100%;
                object-fit: cover; } }

        &-name {
            h3 {
                margin-bottom: 8px;
                font-size: 24px;
                line-height: 29px;
                font-weight: 600;
                font-family: 'Outfit', sans-serif;
                color: $accent-4; } }

        p {
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            color: $accent-2; } } }

.team {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $lg) {
        flex-direction: column; }

    &__item {
        background: $accent;
        border-radius: 24px;
        width: 100%;

        h3 {
            padding: 32px;
            color: $accent-4;
            font-size: 24px;
            line-height: 29px;
            font-weight: 600;
            font-family: 'Outfit', sans-serif;
            border-bottom: 4px solid $accent-1;

            @media (max-width: $lg) {
                padding: 20px 32px; } }

        &-text {
            display: flex;
            align-items: center;
            padding-left: 32px;
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            font-family: 'Outfit', sans-serif;
            height: 72px;

            @media (max-width: $lg) {
                height: 60px; }

            .dots {
                border-radius: 100%;
                background: #D9D9D9;
                width: 6px;
                height: 6px;
                margin-left: 16px; }

            img {
                margin-left: 16px;
                margin-right: 12px; } } } }

table {
    width: 100%; }
