.promo {
    display: flex;
    gap: 36px;
    padding: 60px 40px;

    @media (max-width: $lg) {
        flex-direction: column-reverse;
        gap: 20px; }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 412px;

        @media (max-width: $lg) {
            max-width: 100%; } }

    img {
        border-radius: 24px;
        width: 100%;
        object-fit: cover;

        @media (max-width: $lg) {
            width: 100%;
            height: auto; } }

    h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 120%;
        font-family: 'Outfit', sans-serif;
        color: #2B3042;

        @media (max-width: $lg) {
            font-size: 40px;
            line-height: 50px; } }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px; } }
